import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "primereact/resources/primereact.min.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import LandingPage from "./Bot-components/LandingPage";
import Login from "./Accounts/Login";
import UploadPage from "./Bot-components/UploadPage";
import ValidateNowcerts from "./Accounts/ValidateNowcerts";
import History from "./Bot-components/History";
import WidgetsLandingPage from "./Bot-components/Bot-widgets/WidgetsLandingPage";
import PolicyComparision from "./Bot-components/Bot-widgets/PolicyComparision";

function App() {
  return (
    <div className="">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />}></Route>
          <Route path="/bridge" element={<ValidateNowcerts />}></Route>
          <Route path="/bot" element={<LandingPage />}></Route>
          <Route path="/upload" element={<UploadPage />}></Route>
          <Route path="/history" element={<History />}></Route>
          <Route path="/agents" element={<WidgetsLandingPage />}></Route>
          <Route path="/policy" element={<PolicyComparision />}></Route>

        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
