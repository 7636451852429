class CommonValues {
  static Logout = (navigate: any) => {
    localStorage.removeItem("igtoken");
    localStorage.removeItem("tenantId");
    localStorage.removeItem("userName");
    localStorage.removeItem("SocialLogin");
    localStorage.removeItem("RoleId");

    navigate("/");
  };

  static GetToken = () => {
    return localStorage.getItem("igtoken");
  };

  static GetTenantId = () => {
    return localStorage.getItem("tenantId");
  };

  static SetToken = (token: any) => {
    localStorage.setItem("igtoken", token);
  };

  static SetTenantId = (tenantId: any) => {
    localStorage.setItem("tenantId", tenantId);
  };

  static ClearAllValues = () => {
    localStorage.removeItem("igtoken");
    localStorage.removeItem("tenantId");
    localStorage.removeItem("userName");
    localStorage.removeItem("SocialLogin");
    localStorage.removeItem("RoleId");
  };
}

export default CommonValues;
