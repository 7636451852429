import { useNavigate } from "react-router-dom";
import UploadIcon from "../Assets/Images/Document Upload_B.svg";
import HistoryIcon from "../Assets/Images/History_B.svg";
import HomeIcon from "../Assets/Images/Home_V.svg";
import WidgetIcon from "../Assets/Images/Layout 3.svg";

export default function Sidebar() {
  const navigate = useNavigate();

  const onUploadClick = () => {
    navigate("/upload");
  };
  const onHistoryClick = () => {
    navigate("/history");
  };
  const onHomeIconClick = () => {
    navigate("/bot");
  };
  const onWidgetClick=()=> {
    navigate("/agents");
  };
  return (
    <>
      <div className="support-bot-sidebar" onClick={onHomeIconClick}>
        <span className="" title="Home">
          <img src={HomeIcon} className="sidebar-menu-img" />
        </span>
      </div>
      <div className="support-bot-sidebar" onClick={onHistoryClick}>
        <span className="" title="History">
          <img src={HistoryIcon} className="sidebar-menu-img" />
        </span>
      </div>
      <div className="support-bot-sidebar mt-2" onClick={onUploadClick}>
        <span className="" title="Upload">
          <img src={UploadIcon} className="sidebar-menu-img" />
        </span>
      </div>

      <div className="support-bot-sidebar mt-2" onClick={onWidgetClick}>
        <span className="" title="Agents">
          <img src={WidgetIcon} className="sidebar-menu-img" />
        </span>
      </div>
    </>
  );
}
