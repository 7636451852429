import axios from "axios";
import { ProgressBar } from "primereact/progressbar";
import { Toast } from "primereact/toast";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../Common/Header";
import Sidebar from "../Common/Sidebar";
import CommonValues from "../Common/Utils";

export default function UploadPage() {
  const [loading, setLoading] = useState(false);
  const [processFileLoading, setProcessFileLoading] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState<any>([]);
  const [fileNames, setFileNames] = useState<any>([]);
  const [fileResponse, setFileResponse] = useState<any>([]);
  const [showIframe, setShowIframe] = useState(false);
  const [fileError, setFileError] = useState("");
  const [progressLoader, setProgressLoader] = useState(0);
  const toast: any = useRef("");
  const [fileCount, setFileCount] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    let token = CommonValues.GetToken();
    if (token == "" || token == null) {
      CommonValues.Logout(navigate);
    }
  }, []);
  const onFileSelectHandler = (e: any) => {
    setProgressLoader(0);
    const files = e.target.files ? Array.from(e.target.files) : [];
    if (files.length > 0) {
      const fileNamesArray = files.map((file: any) => file.name);

      setFileNames(fileNamesArray);
      setSelectedFiles(files);
      setFileError("");
    } else {
      setFileNames([]);
      setFileError("No files selected");
      setSelectedFiles([]);
    }
  };

  const checkValidation = () => {
    let returnValue = true;
    setFileError("");

    if (selectedFiles == null || selectedFiles.length == 0) {
      setFileError("Please select at least one file to upload.");
      returnValue = false;
    }
    return returnValue;
  };
  const onSubmitFile = async () => {
    if (checkValidation()) {
      setProcessFileLoading(true);

      let LoadingPercent = 100 / selectedFiles.length;
      try {
        const uploadPromises = selectedFiles.map((file: any, index: number) =>
          uploadFile(file, LoadingPercent, index)
        );
        await Promise.all(uploadPromises);
        setShowIframe(true);
      } catch (error) {
        console.error("One or more files failed to upload.", error);
      } finally {
        setProcessFileLoading(false);
      }
    }
  };

  const uploadFile = (
    file: File,
    loadingPercent: any,
    fileNumber: any
  ): Promise<any> => {
    return new Promise((resolve, reject) => {
      const FormData = require("form-data");
      const data = new FormData();
      data.append("formFile", file);

      const token = CommonValues.GetToken();
      const config = {
        method: "post",
        url: `${process.env.REACT_APP_DataFabric_Url}/api/ncsupportbot/upload`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: data,
      };

      axios(config)
        .then((response: any) => {
          setProgressLoader((prevProgressLoader) =>
            Math.round(prevProgressLoader + loadingPercent)
          );
          setFileCount(fileNumber);
          toast.current.show({
            severity: "success",
            detail: `File upload successful: ${file.name}`,
            life: 3000,
          });
          resolve(response.data);
        })
        .catch((error: any) => {
          console.error(`File upload failed: ${file.name}`, error);
          toast.current.show({
            severity: "error",
            detail: `File upload failed: ${file.name}`,
            life: 3000,
          });
          setProgressLoader((prevProgressLoader) =>
            Math.round(prevProgressLoader + loadingPercent)
          );
          reject(error);
          setFileNames([]);
          setSelectedFiles([]);
        });
    });
  };

  const onBackButton = () => {
    setShowIframe(false);
    setFileNames([]);
    setSelectedFiles([]);
    setProgressLoader(0);
    setFileCount(0);
  };
  const onCloseIconClick = (fileName: any) => {
    const updatedSelectedFiles = selectedFiles.filter(
      (file: any) => file.name !== fileName
    );
    setSelectedFiles(updatedSelectedFiles);

    const fileNamesArray = updatedSelectedFiles.map((file: any) => file.name);
    setFileNames(fileNamesArray);
  };
  return (
    <div className="upload-page">
      <Toast ref={toast} />

      <Header />
      <div className="content-wrapper container-fluid d-flex justify-content-center p-0">
        <div className="row main-content mx-auto  w-100 ">
          <div className="col support-bot-sidebar-main-div">
            <Sidebar />
          </div>
          <div className="col d-flex justify-content-center ">
            <div className=" d-flex justify-content-center align-items-center  flex-column container w-75 position-relative">
              {showIframe ? null : (
                <div className="row mx-auto mb-2 ">
                  <div className="col-12 ">
                    <h5 className="mt-2">
                      Upload the documents you would like to use for training
                    </h5>{" "}
                  </div>
                </div>
              )}

              <div className="row upload-box position-relative mx-auto p-2">
                {processFileLoading ? (
                  <div className="d-grid align-items-center">
                    <span>
                      {fileCount} of {selectedFiles.length}
                      <ProgressBar value={progressLoader}></ProgressBar>
                      &nbsp; Uploading....
                    </span>
                  </div>
                ) : (
                  <>
                    {showIframe ? (
                      <>
                        <div className="col-12 text-start back-btn-position">
                          <input
                            className="btn btn-outline-info "
                            type="button"
                            value="Back"
                            onClick={onBackButton}
                          />
                        </div>

                        <div className="col-12  success-message-box w-75 mx-auto">
                          <h5 className="success-message">
                            All the files you submitted are successfully
                            uploaded.{" "}
                          </h5>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="form-group col-12  d-flex justify-content-center align-items-center position-relative flex-column mb-2">
                          <div className="file-upload">
                            <input
                              type="file"
                              className="custom-file-input"
                              id="fileInput"
                              onChange={onFileSelectHandler}
                              name="fileData"
                              accept=".pdf"
                              multiple
                            />
                            <label
                              htmlFor="fileInput"
                              className="custom-file-label "
                            >
                              Choose Files
                            </label>
                          </div>

                          <span className="text-danger text-12 ">
                            {fileError}
                          </span>
                        </div>
                        {fileNames.length > 0 ? (
                          <div className="form-group col-12 file-name-box mb-2">
                            {fileNames.map((fileName: any, index: number) => (
                              <span
                                key={index}
                                className="file-name "
                              >
                                {fileName}{" "}
                                <i
                                  className="fa fa-close ms-2 show-cursor "
                                  aria-hidden="true"
                                  title="Close"
                                  onClick={() => onCloseIconClick(fileName)}
                                ></i>
                              </span>
                            ))}{" "}
                          </div>
                        ) : null}
                        {selectedFiles.length > 0 ? (
                          <div className="col-12 upload-btn">
                            <input
                              className="btn submit-btn"
                              type="button"
                              value="Submit Document "
                              onClick={onSubmitFile}
                            />
                          </div>
                        ) : null}
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
