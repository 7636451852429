import axios from "axios";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Toast } from "primereact/toast";
import { useEffect, useRef, useState } from "react";
import ReactMarkdown from "react-markdown";
import { useNavigate } from "react-router-dom";
import remarkGfm from "remark-gfm";
import Header from "../Common/Header";
import Sidebar from "../Common/Sidebar";
import CommonValues from "../Common/Utils";
import SupportLoader from "../CommonControls/SupportLoader";

export default function History() {
  const [historyData, setHistoryData] = useState([]);
  const toast: any = useRef("");
  const navigate = useNavigate();
  const [historyLoading, setHistoryLoading] = useState(false);
  useEffect(() => {
    getHistoryDetails();
  }, []);

  const getHistoryDetails = () => {
    const token = CommonValues.GetToken();

    var config = {
      method: "get",
      url: `${process.env.REACT_APP_DataFabric_Url}/api/ncsupportbot/nc-support-bot-history`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    setHistoryLoading(true);
    axios(config)
      .then((response: any) => {
        setHistoryData(response.data);
        setHistoryLoading(false);
      })
      .catch((error: any) => {
        setHistoryLoading(false);

        if (error.response.status == 401) {
          CommonValues.Logout(navigate);
        } else {
          toast.current.show({
            severity: "error",
            detail: "Unknown error while getting history details ",
            life: 3000,
          });
        }
      });
  };

  const viewAnswerTemplate = (rowData: any) => {
    let mainAnswer = "";

    try {
      const parsedResponse = JSON.parse(rowData.response);
      const trimmedAnswer = parsedResponse.answer.trim();
      const answerParts = trimmedAnswer.split(
        /\$\$?FollowupQuestions\$\$?|FollowupQuestions/
      );
      mainAnswer = answerParts[0].trim();
    } catch (error) {
      console.error("Failed to parse response JSON:", error);
    }

    return (
      <span>
        <ReactMarkdown
          remarkPlugins={[remarkGfm]}
          className="answer-block"
        >
          {mainAnswer}
        </ReactMarkdown>
      </span>
    );
  };

  return (
    <div className="history-page">
      <Toast ref={toast} />

      <Header />
      <div className="content-wrapper container-fluid d-flex justify-content-center p-0">
        <div className="row main-content mx-auto  w-100 ">
          <div className="col support-bot-sidebar-main-div">
            <Sidebar />
          </div>
          <div className="col d-flex justify-content-center ">
            <div className=" d-flex  align-items-center  flex-column  container-fluid w-100 ">
              <div className="row mx-auto mb-2 w-100 ">
                <h4 className="text-center p-3">History Details</h4>
                <div
                  className="col-12 mt-4"
                  style={{ minHeight: "300px" }}
                >
                  {historyLoading ? (
                    <div className="d-flex justify-content-center align-items-center ">
                      <SupportLoader />
                    </div>
                  ) : (
                    <DataTable
                      value={historyData}
                      showGridlines
                      paginator
                      paginatorTemplate="PrevPageLink PageLinks NextPageLink"
                      rows={8}
                      selectionMode="single"
                    >
                      <Column
                        header="Question"
                        field="question"
                        sortable
                      ></Column>
                      <Column
                        header="Answer"
                        body={viewAnswerTemplate}
                        sortable
                      ></Column>
                      <Column
                        header="Feedback"
                        field="feedback"
                        sortable
                      ></Column>
                    </DataTable>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
