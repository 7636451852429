import { useEffect, useRef, useState } from "react";
import Header from "../../Common/Header";
import Sidebar from "../../Common/Sidebar";
import { Toast } from "primereact/toast";
import { useNavigate } from "react-router-dom";
import CommonValues from "../../Common/Utils";
import axios from "axios";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

export default function PolicyComparision() {
  const [firstSelectedFile, setFirstSelectedFile] = useState<any>([]);
  const [secondSelectedFile, setSecondSelectedFile] = useState<any>([]);
  const [fileName1, setFileName1] = useState("");
  const [fileName2, setFileName2] = useState("");
  const [comparedResponse, setComparedResponse] = useState("");
  const toast: any = useRef("");
  const navigate = useNavigate();

  const onFirstFileSelect = (e: any) => {
    if (e.target.files && e.target.files[0]) {
      setFirstSelectedFile(e.target.files[0]);
      setFileName1(e.target.files[0].name);
    }
    e.preventDefault();
  };

  const onSecondFileSelect = (e: any) => {
    if (e.target.files && e.target.files[0]) {
      setSecondSelectedFile(e.target.files[0]);
      setFileName2(e.target.files[0].name);
    }
    e.preventDefault();
  };
  const onCloseIconClick = (fileName: string) => {
    if (fileName === fileName1) {
      setFirstSelectedFile(null);
      setFileName1("");
    } else if (fileName === fileName2) {
      setSecondSelectedFile(null);
      setFileName2("");
    }
  };
  const onBackButton = () => {
    setFirstSelectedFile(null);
    setFileName1("");
    setSecondSelectedFile(null);
    setFileName2("");
  };
  const onCompareFileClick = () => {
    const FormData = require("form-data");
    const data = new FormData();
    // data.append("formFile", file);
    data.append("FirstFormFile", firstSelectedFile);
    data.append("SecondFormFile", secondSelectedFile);
    const token = CommonValues.GetToken();
    const config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_NcSupport_Url}/api/ncpolicyagent/compare`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };

    axios(config)
      .then((response: any) => {
        console.log(response.data);
        setComparedResponse(response.data);
      })
      .catch((error: any) => {
        console.error(error);
        toast.current.show({
          severity: "error",
          detail: `File upload failed`,
          life: 3000,
        });
      });
  };

  return (
    <div className="widget-landing-page">
      <Toast ref={toast} />

      <Header />
      <div className="content-wrapper container-fluid d-flex justify-content-center p-0">
        <div className="row main-content mx-auto  w-100 ">
          <div className="col support-bot-sidebar-main-div">
            <Sidebar />
          </div>
          <div className="col d-flex justify-content-center ">
            <div className=" d-flex  align-items-center  flex-column  container-fluid w-100 ">
              <div className="row mx-auto mb-2 w-100 container mt-5 d-flex justify-content-center">
                <h4 className="text-center p-3"></h4>
                <div className="col-4 form-group text-center mb-2 mt-3 choose-file-box first-choose-file-box">
                  <div className="file-upload   position-relative">
                    <span className="d-block h5">First Policy</span>

                    <input type="file" className="custom-file-input" id="firstFileInput" onChange={onFirstFileSelect} name="fileData" accept=".pdf" />
                    <label htmlFor="firstFileInput" className="custom-file-label2 ">
                      Choose File
                    </label>
                    {fileName1.length > 0 ? (
                      <span className="file-name ">
                        {fileName1}{" "}
                        <i className="fa fa-close ms-2 show-cursor " aria-hidden="true" title="Close" onClick={() => onCloseIconClick(fileName1)}></i>
                      </span>
                    ) : null}
                  </div>

                  {/* <span className="text-danger text-12 ">{fileError}</span> */}
                </div>
                <div className="col-4 form-group  mb-2 text-center mt-3  choose-file-box  ">
                  <div className="file-upload">
                    <span className="d-block h5">Second Policy</span>

                    <input
                      type="file"
                      className="custom-file-input"
                      id="secondFileInput"
                      onChange={onSecondFileSelect}
                      name="fileData2"
                      accept=".pdf"
                      // multiple
                    />
                    <label htmlFor="secondFileInput" className="custom-file-label2 ">
                      Choose File
                    </label>
                    {fileName2.length > 0 ? (
                      <span className="file-name ">
                        {fileName2}{" "}
                        <i className="fa fa-close ms-2 show-cursor " aria-hidden="true" title="Close" onClick={() => onCloseIconClick(fileName2)}></i>
                      </span>
                    ) : null}
                  </div>
                  {/* <span className="text-danger text-12 ">{fileError}</span> */}
                </div>
                <div className="col-12  upload-btn mt-4">
                  <input className="btn submit-btn" type="button" value="Summarise and Compare Policy" onClick={onCompareFileClick} />
                </div>
              </div>
              {comparedResponse.length > 0 ? (
                <div className="row">
                  <div className="col-12 my-2 border compared-policy-response">
                    <input className="btn btn-outline-info " type="button" value="Back" onClick={onBackButton} />
                    <ReactMarkdown remarkPlugins={[remarkGfm]} className="answer-block">
                      {comparedResponse}
                    </ReactMarkdown>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
