import { useEffect, useRef, useState } from "react";
import Header from "../../Common/Header";
import Sidebar from "../../Common/Sidebar";
import { Toast } from "primereact/toast";
import { useNavigate } from "react-router-dom";
import PolicyComparision from "../../Assets/Images/policy-comparision.svg";
import PolicySummarize from "../../Assets/Images/policy-summ.svg";
import TalkToDb from "../../Assets/Images/talktodb.svg";
import SnakeBlue from "../../Assets/Images/snake-blue.svg";
import SnakeBlack from "../../Assets/Images/snake-b.svg";
import Email from "../../Assets/Images/email.svg";
import Task from "../../Assets/Images/task.svg";
import Umbrella from "../../Assets/Images/unbrella.svg";
import Call from "../../Assets/Images/calls.svg";
import Cross from "../../Assets/Images/cross.svg";
import Automate from "../../Assets/Images/automate.svg";
import Rectangle from "../../Assets/Images/Rounded rectangle.svg";
import Dot from "../../Assets/Images/Dot.svg";

export default function WidgetsLandingPage() {
  const toast: any = useRef("");
  const navigate = useNavigate();

  const onPolicyComparisionClick = () => {
    navigate("/policy");
  };
  return (
    <div className="widget-landing-page">
      <Toast ref={toast} />

      <Header />
      <div className="content-wrapper container-fluid d-flex justify-content-center p-0">
        <div className="row main-content mx-auto  w-100 ">
          <div className="col support-bot-sidebar-main-div">
            <Sidebar />
          </div>
          <div className="col d-flex justify-content-center ">
            <div className=" d-flex  align-items-center  flex-column  container-fluid w-100 ">
              <div className="row mx-auto mb-2 mt-5 ">
                {/* <h4 className="text-center p-3">Agents</h4> */}
                <div className="col-3 d-flex align-items-center justify-content-center mb-3">
                  <img src={SnakeBlue} />
                </div>
                <div className="col-6 mb-3">
                  <h4 className="widget-landing-heading">
                    Momentum Insurance <span> A.I. </span>
                    <br /> Agents
                  </h4>
                </div>
                <div className="col-3 d-flex  align-items-center justify-content-center mb-3">
                  <img src={SnakeBlack} />
                </div>
                <div className="col-3 d-flex  align-items-center justify-content-end ">
                  <img src={Email} />
                </div>
                <div className="col-6 text-center text-20">
                  <span>Automate your agency tasks with our Insurance A.I. agents.</span>
                  <br />
                  <span>Discover and Utilize them in to increase your productivity.</span>
                </div>
                <div className="col-3 d-flex  align-items-center justify-content-end ">
                  <img src={Task} />
                </div>
                <div className="col-4 position-relative">
                  <img src={Cross}  className="position-absolute bottom-0"/>
                  <img src={Dot}   className="position-absolute start-0"/>
                  <img src={Umbrella}  className="position-absolute start-0" />
                </div>
                <div className="col-4 position-relative">
                  <img src={Automate} className="position-absolute end-0" />
                  <img src={Rectangle} className="position-absolute end-0"/>
                </div>
                <div className="col-4 position-relative">
                  <img src={Dot}  className="position-absolute start-0  "/>
                  <img src={Call}  className="position-absolute start-0  "/>
                </div>
              </div>

              <div className="row mx-auto mb-2 mt-5 d-flex justify-content-evenly ">
                <div className="col-3">
                  <div className="landing-page-blocks show-cursor" onClick={onPolicyComparisionClick}>
                    <span className="img-span">
                      <img src={PolicyComparision} className="" />
                    </span>
                    <span className="landing-page-title">Policy Comparision</span>
                    <span className="text-12">
                      It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The
                      point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters.
                    </span>
                  </div>
                </div>

                <div className="col-3">
                  <div
                    className="landing-page-blocks show-cursor"
                    // onClick={onCoiScanClick}
                  >
                    {" "}
                    <span className="img-span">
                      <img src={PolicySummarize} className="" />
                    </span>
                    <span className="landing-page-title">Vehicle</span>
                    <span className="text-12">
                      It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The
                      point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters.
                    </span>
                  </div>
                </div>
                <div className="col-3">
                  <div
                    className="landing-page-blocks show-cursor"
                    // onClick={onCoiScanClick}
                  >
                    <span className="img-span">
                      <img src={TalkToDb} className="" />
                    </span>

                    <span className="landing-page-title">Talk to Database</span>

                    <span className="text-12 d-block ">
                      It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The
                      point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters.
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
