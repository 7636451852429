import axios from "axios";
import { Toast } from "primereact/toast";
import { useEffect, useRef, useState } from "react";
import ReactMarkdown from "react-markdown";
import { useNavigate } from "react-router-dom";
import remarkGfm from "remark-gfm";
import AnswerIcon from "../Assets/Images/answer-icon.svg";
import CommunicationIcon from "../Assets/Images/communication-icon.svg";
import Header from "../Common/Header";
import Sidebar from "../Common/Sidebar";
import CommonValues from "../Common/Utils";
import SupportLoader from "../CommonControls/SupportLoader";

interface ChatResponse {
  answer: string;
  thoughts: string;
  dataPoints: { title: string; content: string }[];
  citationBaseUrl: string;
  error: string;
  question: string;
  transactionId: string;
  like: boolean;
  disLike: boolean;
  feedback: string;
  showFeedback: boolean;
  feedbackLoading: boolean;
}

export default function LandingPage() {
  const [question, setQuestion] = useState("");
  const [questionloading, setQuestionLoading] = useState(false);
  const [chatResponses, setChatResponses] = useState<ChatResponse[]>([]);
  const [avatarName, setAvatarName] = useState<string | null>("");
  const toast: any = useRef("");
  const navigate = useNavigate();
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);
  const chatContainerRef = useRef<HTMLDivElement>(null);
  const prevChatResponses = usePrevious<ChatResponse[]>(chatResponses);

  function usePrevious<T>(value: T): T | undefined {
    const ref = useRef<T>();
    useEffect(() => {
      ref.current = value;
    }, [value]);
    return ref.current;
  }
  useEffect(() => {
    let token = CommonValues.GetToken();
    if (token == "" || token == null) {
      CommonValues.Logout(navigate);
    }
    adjustTextareaHeight();
    setAvatarName(localStorage.getItem("AvatarName"));
  }, []);

  useEffect(() => {
    if (prevChatResponses && chatResponses.length > prevChatResponses.length) {
      const lastResponse = chatResponses[chatResponses.length - 1];
      const prevLastResponse = prevChatResponses[prevChatResponses.length - 1];

      if (
        lastResponse &&
        prevLastResponse &&
        lastResponse.answer !== prevLastResponse.answer
      ) {
        scrollToBottom();
      }
    }
  }, [chatResponses, prevChatResponses]);

  const onQuestionChange = (e: any) => {
    setQuestion(e.target.value);
    adjustTextareaHeight();
  };
  const onFeedbackChange = (transactionId: string, newFeedback: string) => {
    setChatResponses((prevResponses) =>
      prevResponses.map((response) =>
        response.transactionId === transactionId
          ? { ...response, feedback: newFeedback }
          : response
      )
    );
  };
  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  };
  const adjustTextareaHeight = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = "auto";
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  };
  const onThumbsUpClick = (
    transactionId: any,
    like: any,
    disLike: any,
    feedBack: any
  ) => {
    updateReaction(transactionId, like, disLike, feedBack);
  };
  const onThumbsDownClick = (
    transactionId: any,
    like: any,
    disLike: any,
    feedBack: any
  ) => {
    updateReaction(transactionId, like, disLike, feedBack);
  };
  const onFeedbackSubmit = (
    transactionId: any,
    like: any,
    disLike: any,
    feedBack: any
  ) => {
    setChatResponses((prevResponses) =>
      prevResponses.map((response) =>
        response.transactionId === transactionId
          ? { ...response, feedbackLoading: true }
          : response
      )
    );
    updateReaction(transactionId, like, disLike, feedBack);
  };
  const onEnterClick = (e: any) => {
    if (e.keyCode == 13) {
      e.preventDefault();
      sendQuestion(question);
    }
  };
  const toggleFeedbackVisibility = (transactionId: string) => {
    setChatResponses((prevResponses) =>
      prevResponses.map((response) =>
        response.transactionId === transactionId
          ? { ...response, showFeedback: !response.showFeedback }
          : response
      )
    );
  };
  const sendQuestion = (que: any) => {
    var data = JSON.stringify({
      History: [
        {
          User: que,
          chat: null,
        },
      ],
      Overrides: {
        SemanticRanker: false,
        RetrievalMode: "Vector",
        SemanticCaptions: null,
        ExcludeCategory: null,
        Top: 5,
        Temperature: null,
        PromptTemplate: null,
        PromptTemplatePrefix: null,
        PromptTemplateSuffix: null,
        SuggestFollowupQuestions: true,
      },
      LastUserQuestion: "",
      Approach: 0,
    });
    setQuestionLoading(true);
    const token = CommonValues.GetToken();

    var config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_DataFabric_Url}/api/ncsupportbot/chat`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };

    axios(config)
      .then((response: any) => {
        setQuestionLoading(false);

        const responseData: ChatResponse = {
          ...response.data,
          question: que,
          like: false,
          disLike: false,
          feedback: "",
          showFeedback: false,
          feedbackLoading: false,
        };
        setChatResponses((prevResponses) => [...prevResponses, responseData]);
        setQuestion("");
      })
      .catch((error: any) => {
        setQuestionLoading(false);
        if (error.response.status == 401) {
          toast.current.show({
            severity: "error",
            detail: "Unknown error while sending question",
            life: 3000,
          });

          CommonValues.Logout(navigate);
        }
      });
  };
  const updateReaction = (
    transactionId: any,
    like: any,
    disLike: any,
    feedBack: any
  ) => {
    const token = CommonValues.GetToken();
    var data = {
      TransactionId: transactionId,
      Like: like,
      DisLike: disLike,
      Feedback: feedBack,
    };

    var config = {
      method: "put",
      url: `${process.env.REACT_APP_DataFabric_Url}/api/ncsupportbot/nc-support-bot-history`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };

    axios(config)
      .then((response: any) => {
        toast.current.show({
          severity: "success",
          detail: "Saved  Successfully!",
          life: 3000,
        });

        setChatResponses((prevResponses) =>
          prevResponses.map((response) =>
            response.transactionId === transactionId
              ? {
                  ...response,
                  like: like,
                  disLike: disLike,
                  feedBack: feedBack,
                  feedbackLoading: false,
                }
              : response
          )
        );
      })
      .catch((error: any) => {
        setChatResponses((prevResponses) =>
          prevResponses.map((response) =>
            response.transactionId === transactionId
              ? { ...response, feedbackLoading: false }
              : response
          )
        );
        if (error.response.status == 401) {
          toast.current.show({
            severity: "error",
            detail: "Unknown error while submit ",
            life: 3000,
          });

          CommonValues.Logout(navigate);
        }
      });
  };
  const onCopyResponseClick = (copiedData: any) => {
    navigator.clipboard
      .writeText(copiedData)
      .then(() => {
        toast.current.show({
          severity: "success",
          detail: "Copied  Successfully!",
          life: 3000,
        });
      })
      .catch(() => {
        toast.current.show({
          severity: "error",
          detail: "Unknown error occurred while coping data",
          life: 3000,
        });
      });
  };
  const onFollowupQuestionClick = (followupQuestion: any) => {
    //  setQuestion(followupQuestion);
    sendQuestion(followupQuestion);
  };
  return (
    <div className="landing-page">
      <Toast ref={toast} />
      <Header />
      <div className="content-wrapper container-fluid d-flex justify-content-center p-0">
        <div className="row main-content mx-auto  w-100 ">
          <div className="col support-bot-sidebar-main-div">
            <Sidebar />
          </div>
          <div className="col d-flex justify-content-center">
            <div className=" d-flex justify-content-center container w-75 position-relative ">
              <div
                className="row w-100 "
                style={{ maxHeight: "100vh", overflowY: "auto" }}
                ref={chatContainerRef}
              >
                <>
                  {chatResponses.map((response, index) => {
                    const trimmedAnswer = response.answer.trim();
                    let mainAnswer = trimmedAnswer;
                    let followUpQuestions: string[] = [];

                    if (trimmedAnswer.includes("$$FollowupQuestions$$")) {
                      const answerParts = trimmedAnswer.split(
                        "$$FollowupQuestions$$"
                      );
                      mainAnswer = answerParts[0];
                      followUpQuestions = answerParts[1]
                        ? answerParts[1]
                            .trim()
                            .split("\n")
                            .filter((q) => q)
                        : [];
                    } else {
                      const answerParts =
                        trimmedAnswer.split("FollowupQuestions");
                      mainAnswer = answerParts[0];
                      followUpQuestions = answerParts[1]
                        ? answerParts[1]
                            .trim()
                            .split("\n")
                            .filter((q) => q)
                        : [];
                    }

                    const isLastAnswer = index === chatResponses.length - 1;

                    return (
                      <div
                        className={`col-12 ${
                          isLastAnswer && questionloading == false
                            ? "last-answer-block"
                            : "question-answer-block"
                        } mt-3`}
                      >
                        <div className="d-flex flex-direction-row align-items-center">
                          <span className="me-1 avtar border-rounded">
                            {avatarName}
                          </span>{" "}
                          <div className=" mb-1">
                            <b>{response.question}</b>
                          </div>
                        </div>
                        <div className="d-flex flex-direction-row">
                          <span className="me-1">
                            <img
                              src={AnswerIcon}
                              className="me-2"
                              height={28}
                            />
                          </span>
                          <div>
                            <ReactMarkdown
                              remarkPlugins={[remarkGfm]}
                              className="answer-block"
                            >
                              {mainAnswer}
                            </ReactMarkdown>
                            {/* <span className="source-heading">Sources:</span> */}
                            <h6>Sources:</h6>
                            {response.dataPoints.map((source, i) => (
                              <span
                                key={i}
                                className="sources-box me-3 "
                              >
                                <span className="source-wrap d-flex">
                                  <span className="countbox">{i + 1}</span>
                                  <span className="namebox">
                                    {source.title}
                                  </span>
                                </span>
                              </span>
                            ))}
                            <div className="icon-box mb-2">
                              <i
                                className={
                                  response.like
                                    ? "fa fa-thumbs-up active "
                                    : "fa fa-thumbs-up  "
                                }
                                aria-hidden="true"
                                onClick={() =>
                                  onThumbsUpClick(
                                    response.transactionId,
                                    !response.like,
                                    null,
                                    response.feedback
                                  )
                                }
                              ></i>
                              <i
                                className={
                                  response.disLike
                                    ? "fa fa-thumbs-down active "
                                    : "fa fa-thumbs-down  "
                                }
                                aria-hidden="true"
                                onClick={() =>
                                  onThumbsDownClick(
                                    response.transactionId,
                                    null,
                                    !response.disLike,
                                    response.feedback
                                  )
                                }
                              ></i>
                              <i
                                className="fa fa-solid fa-copy"
                                aria-hidden="true"
                                title="Copy response"
                                onClick={() => onCopyResponseClick(mainAnswer)}
                              ></i>
                              <i
                                className="fa fa-comments-o"
                                aria-hidden="true"
                                title="Feedback"
                                onClick={() =>
                                  toggleFeedbackVisibility(
                                    response.transactionId
                                  )
                                }
                              ></i>

                              {response.showFeedback && (
                                <div className="feedback-box mb-3">
                                  <div className="row">
                                    <div className="col-12">
                                      <span
                                        className="close-feedback"
                                        onClick={() =>
                                          toggleFeedbackVisibility(
                                            response.transactionId
                                          )
                                        }
                                      >
                                        {" "}
                                        <i
                                          className="fa fa-close "
                                          aria-hidden="true"
                                          title="Close"
                                        ></i>
                                      </span>
                                      <h6>
                                        Your feedback will improve this product.
                                      </h6>
                                      <div className="">
                                        <textarea
                                          name="feedback"
                                          onChange={(e) =>
                                            onFeedbackChange(
                                              response.transactionId,
                                              e.target.value
                                            )
                                          }
                                          value={response.feedback}
                                          className="form-control mb-3 "
                                          rows={4}
                                          placeholder="Your feedback..."
                                        />
                                      </div>
                                      {response.feedbackLoading ? (
                                        <div className="col-12 d-flex justify-content-end">
                                          <SupportLoader />
                                        </div>
                                      ) : (
                                        <div className="col-12 text-end">
                                          <input
                                            className="btn btn-outline-info "
                                            type="button"
                                            value="Submit"
                                            onClick={() =>
                                              onFeedbackSubmit(
                                                response.transactionId,
                                                response.like,
                                                response.disLike,
                                                response.feedback
                                              )
                                            }
                                          />
                                        </div>
                                      )}
                                    </div>
                                  </div>{" "}
                                </div>
                              )}
                            </div>
                            {followUpQuestions.length > 0 && (
                              <>
                                <h6>Follow-up Questions:</h6>

                                {followUpQuestions.map((question, i) => (
                                  <span
                                    key={i}
                                    className="followup-que"
                                    onClick={() =>
                                      onFollowupQuestionClick(question)
                                    }
                                  >
                                    {question}
                                  </span>
                                ))}
                              </>
                            )}{" "}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </>
                {questionloading ? (
                  <div className="col-12 mt-2 loader-bottom">
                    <SupportLoader />
                  </div>
                ) : null}

                {chatResponses.length > 0 ? null : (
                  <div className="col-12 d-flex justify-content-center align-items-center">
                    <h4 className=" d-flex justify-content-center align-items-center w-100">
                      How may I assist you today?
                    </h4>
                  </div>
                )}
                <div className="col-12 d-flex justify-content-center align-items-center">
                  <div className="question-text">
                    <div className="position-relative question-text-div">
                      <textarea
                        name="question"
                        onChange={onQuestionChange}
                        value={question}
                        className="form-control question-textarea mb-2 "
                        rows={2}
                        placeholder="Ask me anything about NowCerts..."
                        maxLength={2000}
                        style={{
                          minHeight: `${Math.max(2, question.length / 50)}em`,
                        }}
                        onKeyDown={onEnterClick}
                      />
                      <span className="info-msg mb-2 ">
                        Support Bot can make mistakes. Check important info.
                      </span>

                      <img
                        src={CommunicationIcon}
                        onClick={() => sendQuestion(question)}
                        className="communication-icon"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
