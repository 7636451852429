import { useNavigate } from "react-router-dom";
import MomentumLogo from "../Assets/Images/Nowcerts.png";
import CommonValues from "./Utils";
import { useEffect, useState } from "react";

export default function Header() {
  const navigate = useNavigate();
  const [userName, setUserName] = useState<string | null>("");
  const [isBotPage, setIsBotPage] = useState(false);

  useEffect(() => {
    setUserName(localStorage.getItem("UserName"));
    setIsBotPage(window.location.pathname === "/bot");
  }, []);

  const onLogoClick = () => {
    navigate("/bot");
  };
  const onLogoutClick = () => {
    CommonValues.Logout(navigate);
  };
  return (
    <div className="row support-bot-header p-3 mx-auto">
      <div className="col-lg-6 col-md-6  col-sm-6 col-6 d-flex align-items-center  ">
        <img src={MomentumLogo} className="header-momentum-logo me-2" onClick={onLogoClick} /> <span className="fw-bold">| Support</span>
      </div>
      <div className="col-lg-6 col-md-6  col-sm-6 col-6 text-end">
        {CommonValues.GetToken() != null ? (
          <>
            {isBotPage && (
              <span className="p-4 ">
                Welcome <b>{userName}</b>
              </span>
            )}
            <span className="show-cursor submit-btn p-2 rounded" onClick={onLogoutClick}>
              Logout
            </span>
          </>
        ) : null}
      </div>
    </div>
  );
}
